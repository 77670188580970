import React from 'react';
import mehulImage from './images/mehul.jpg';
import surajImage from './images/suraj.png';
import pradeepImage from './images/pradeep.jpg';
import robotImage from './images/img1.webp';
import motivationImage from './images/img2.webp';

const BoldCharterWebsite = () => {
  return (
    <div className="min-h-screen bg-gray-50 text-gray-900">

      {/* Navigation */}
      <nav className="bg-blue-600 text-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <div className="text-2xl font-bold">BoldCharter</div>
          <div className="space-x-4">
            <a href="#product" className="hover:text-blue-200">Product</a>
            <a href="#team" className="hover:text-blue-200">Team</a>
            <a href="#impact" className="hover:text-blue-200">Impact</a>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <header className="container mx-auto px-6 py-16 text-center">
        <h1 className="text-5xl font-extrabold mb-4 text-blue-700">
          Programmable Teachable General Purpose Robots
        </h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto">
          Enabling everyone to make and program their own autonomous bot as easily as we can chat with AI today.
        </p>
        <div className="mt-8">
          <a 
            href="#contact" 
            className="bg-blue-600 text-white px-8 py-3 rounded-full text-lg hover:bg-blue-700 transition"
          >
            Get Started
          </a>
        </div>
      </header>

      {/* Product Section */}
      <section id="product" className="container mx-auto px-6 py-16 bg-white shadow-lg rounded-lg">
        <h2 className="text-3xl font-bold text-center mb-12">Our Solution - Nova</h2>
        <div className="flex flex-col md:flex-row gap-12 items-center mb-12">
          <div className="md:w-1/2">
            <img 
              src={robotImage} 
              alt="Nova Robot" 
              className="rounded-lg shadow-xl w-full h-auto object-cover"
            />
          </div>
          <div className="md:w-1/2">
            <div className="grid gap-8">
              <div className="text-center p-6 bg-blue-50 rounded-lg">
                <h3 className="text-xl font-semibold mb-2">General Purpose</h3>
                <p>Components include Camera, Microphone, Speaker, Hands, Body, and Wheels.</p>
              </div>
              <div className="text-center p-6 bg-blue-50 rounded-lg">
                <h3 className="text-xl font-semibold mb-2">Programmable</h3>
                <p>Software stack similar to Android. Write low-level APIs with ease.</p>
              </div>
              <div className="text-center p-6 bg-blue-50 rounded-lg">
                <h3 className="text-xl font-semibold mb-2">Teachable</h3>
                <p>Cutting-edge AI/ML algorithms. Program in Python or natural language.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section id="team" className="container mx-auto px-6 py-16 bg-gray-100">
        <h2 className="text-3xl font-bold text-center mb-12">Our Team</h2>

        <div className="max-w-md mx-auto space-y-8">
          <div className="bg-white p-6 rounded-lg shadow-md flex items-center space-x-6">
            <img
              src={mehulImage}
              alt="Mehul Goyal"
              className="w-32 h-32 rounded-full object-cover"
            />
            <div>
              <h3 className="text-xl font-semibold">Mehul Goyal</h3>
              <p className="text-gray-600">
                Ex-Partner Boson AlphaGrep, IITB CS 2015
                Skilled in data-driven decision-making algorithms
              </p>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md flex items-center space-x-6">
            <img
              src={surajImage}
              alt="Suraj Sreenath"
              className="w-32 h-32 rounded-full object-cover"
            />
            <div>
              <h3 className="text-xl font-semibold">Suraj Sreenath</h3>
              <p className="text-gray-600">
                Financial Services Entrepreneur
                15 years of sales experience
              </p>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md flex items-center space-x-6">
            <img
              src={pradeepImage}
              alt="Pradeep"
              className="w-32 h-32 rounded-full object-cover"
            />
            <div>
              <h3 className="text-xl font-semibold">Pradeep</h3>
              <p className="text-gray-600">
                Former Tesla Engineer
                Experienced in Hardware & Firmware Design
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Motivation Section */}
      <section id="impact" className="container mx-auto px-6 py-16 bg-white">
        <h2 className="text-3xl font-bold text-center mb-12">Our Motivation</h2>
        <div className="max-w-4xl mx-auto">
          <div className="flex flex-col md:flex-row items-center gap-12">
            <div className="md:w-1/2">
              <img 
                src={motivationImage} 
                alt="Robotics Education" 
                className="rounded-lg shadow-xl w-full h-auto object-cover"
              />
            </div>
            <div className="md:w-1/2 space-y-6">
              <p className="text-xl text-gray-700">
                We want to democratize robotics, making it accessible for everyone - from professionals to kids as young as 10 years old.
              </p>
              <div className="space-y-4">
                <div className="bg-blue-50 p-4 rounded-lg">
                  <h3 className="font-semibold mb-2">Hardware Accessibility</h3>
                  <p>Making robot development easy and approachable for everyone.</p>
                </div>
                <div className="bg-blue-50 p-4 rounded-lg">
                  <h3 className="font-semibold mb-2">Experimental Learning</h3>
                  <p>Encouraging hands-on learning and experimentation.</p>
                </div>
                <div className="bg-blue-50 p-4 rounded-lg">
                  <h3 className="font-semibold mb-2">Education Revolution</h3>
                  <p>Moving beyond theoretical education to practical implementation.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="container mx-auto px-6 py-16 bg-gray-100">
        <h2 className="text-3xl font-bold text-center mb-12">Contact Us</h2>
        <div className="max-w-md mx-auto bg-white p-8 rounded-lg shadow-md">
          <form 
            action="https://formspree.io/f/mgvendpn" 
            method="POST"
            className="space-y-4"
          >
            <input 
              type="text" 
              name="name"
              placeholder="Your Name" 
              required
              className="w-full p-3 border rounded-lg"
            />
            <input 
              type="email" 
              name="email"
              placeholder="Your Email" 
              required
              className="w-full p-3 border rounded-lg"
            />
            <textarea 
              name="message"
              placeholder="Your Message" 
              required
              className="w-full p-3 border rounded-lg h-32"
            ></textarea>
            <button 
              type="submit" 
              className="w-full bg-blue-600 text-white p-3 rounded-lg hover:bg-blue-700 transition"
            >
              Send Message
            </button>
          </form>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-blue-600 text-white py-6">
        <div className="container mx-auto text-center">
          <p>© 2024 BoldCharter Labs. All Rights Reserved.</p>
          <p className="text-sm mt-2">Incorporation Date: 13/02/2024 | CIN: IN-KA29781336235293W</p>
        </div>
      </footer>
    </div>
  );
};

export default BoldCharterWebsite;
